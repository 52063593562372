"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOOL_ID = exports.PARAM_KEY = exports.PANEL_TITLE = exports.PANEL_ID = exports.EVENTS = exports.DEFAULT_STATE = exports.ADDON_ID = void 0;
var ADDON_ID = "prototypearea/storybook-amp";
exports.ADDON_ID = ADDON_ID;
var PANEL_ID = "".concat(ADDON_ID, "/panel");
exports.PANEL_ID = PANEL_ID;
var PANEL_TITLE = "AMP";
exports.PANEL_TITLE = PANEL_TITLE;
var TOOL_ID = "".concat(ADDON_ID, "/tool");
exports.TOOL_ID = TOOL_ID;
var PARAM_KEY = "amp";
exports.PARAM_KEY = PARAM_KEY;
var EVENTS = {
  RESULT: "".concat(ADDON_ID, "/result"),
  VALIDATION: "".concat(ADDON_ID, "/validation")
};
exports.EVENTS = EVENTS;
var DEFAULT_STATE = {
  isValid: undefined,
  type: "amp",
  html: null
};
exports.DEFAULT_STATE = DEFAULT_STATE;